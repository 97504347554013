"use client";
import RetroGrid from "@/components/magicui/retro-grid";
import WordPullUp from "@/components/magicui/word-pull-up";
import { Flex, Text } from "@radix-ui/themes";
import { useTheme } from "next-themes";
import Image from "next/image";

export default function Home() {
  const { resolvedTheme } = useTheme();

  return (
    <Flex
      className="w-full c min-h-screen"
      justify="center"
      align="center"
      gap="3"
      direction="column"
    >
      {resolvedTheme === "dark" && (
        <Image
          src="/images/tixi-icon-white.svg"
          alt="tixi Logo"
          width={44}
          height={500}
        />
      )}
      {resolvedTheme === "light" && (
        <Image
          src="/images/tixi-icon-dark.svg"
          alt="tixi Logo"
          width={44}
          height={500}
        />
      )}

      <Text className="text-center text-2xl font-bold">Introducing</Text>

      <Flex gap="2" align="center" justify="center" className="mt-4">
        <WordPullUp words="Timesheets Max Pro Ultra" />
        {/* <span className="pointer-events-none z-10 whitespace-pre-wrap bg-gradient-to-b from-[#ffd319] via-[#ff2975] to-[#8c1eff] bg-clip-text text-center text-2xl font-bold leading-none text-transparent">
            Ultra
          </span> */}
      </Flex>

      {/* <AnimatedGradientText>Ultra</AnimatedGradientText> */}
      <RetroGrid />
    </Flex>
  );
}
